<template>
    <Modal class="page-template-selection" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('cms.select_template')}}
        </template>
        
        <template v-slot:body>
            <LoadedContent class="types" :loaded="isLoaded" :error="isError">
                <div 
                    v-for="(type,index) in templates" 
                    :key="index" class="type" 
                    :class="{selected: page.templateCode === type.item_key}" 
                    :title="type.description"
                    @click="selectType(type)"
                >
                    <img :src="getImage(type)" :alt="$t('cms.template_preview')" >
                    <div class="label">
                        {{ type.name }}
                    </div>
                </div>
            </LoadedContent>
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import LoadedContent from '@common/components/base/LoadedContent';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    name: "PageTemplateSelection",
    components: {
        Modal,
        LoadedContent
    },
    emits: ['close'],
    props: {
        opened: Boolean,
        page: {
            type: Object,
            default: () => {}
        }
    },
    setup(props,context){
        const store = useStore();

        store.dispatch("page_template/fetchItems",{});

        const templates = computed(() => store.getters['page_template/getItems']);
        
        const isLoaded = computed(() => store.getters["page_template/isLoaded"]);
        
        const isError = computed(() => store.getters["page_template/isError"]);
        
        const images = require.context('@admin/assets/page_templates/', true, /\.svg$/)

        const getImage = (type) => {
            if(type.image){
                return images('./'+type.image);
            }
            return images('./'+'default/no-image.svg');
        }

        const closeForm = () => {
            context.emit('close');
        }
        
        const selectType = (type) => {
            store.commit('page_editor/updateValues',{values: {templateCode: type.item_key,template: type}});
            closeForm();
        }
        

        return {
            templates,
            getImage,
            closeForm,
            selectType,
            isLoaded,
            isError
        }

    }
}
</script>

<style scoped>
.types{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row;
}
.type{
    margin: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 700;
    width: 12rem;
}
.type>img{
    flex-grow: 1;
    max-height: 10rem;
    max-width: 10rem;
}
.type .label{
    max-width: 12rem;
    margin-top: 0.5rem;
    
}
.type:hover{
    background: #e9fff3;
}
.selected{
    outline: 3px solid #177542;
    background: #e9fff3;
}

</style>